import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  } from "@mui/material";
import OrganizationTitle from "pages/OrganizationTitle/OrganizationTitle";
import { ChangeEvent, useEffect, useState } from "react";
import { UserRoleConfiguration } from "./data/UserRoleConfiguration";
import {
  _addUserRoleConfiguration,
  _getUserRoleConfiguration,
} from "middlewares/Settings/userRole";
import { useParams } from "react-router-dom";
import numericValidator from "services/numericValidator";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import LoadingAndSnackbar from "../LoadingAndSnackbar";

/**
 * Component for managing user role configuration for an organization.
 * @component
 */
const UserRole = () => {
  const { OrganizationId } = useParams();
  const [userRoleConfig, setUserRoleConfig] = useState<UserRoleConfiguration>({
    id: 0,
    orgID: 0,
    numberOfAdmins: 0,
    numberOfStandardUsers: 0,
    numberOfViewers: 0,
  });
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  const [loading, setLoading] = useState(true);

  /**
   * Handles input field changes for numeric values.
   * @param {ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUserRoleConfig((prevState) => ({
      ...prevState,
      [name]: numericValidator.allowNumericValue(value),
    }));
  };

  /**
   * Handles closing the SnackBar component.
   * @param {AlertsProps} state - The new state for the SnackBar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

  /**
   * Fetches the user role configuration for the given organization.
   * @async
   */
  const getUserRoleConfiguration = async () => {
    try {
      setLoading(true);
      const res = await _getUserRoleConfiguration(Number(OrganizationId));
      if (res) {
        setUserRoleConfig({
          ...res,
          orgID: res.orgID ?? Number(OrganizationId),
        });
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles saving the user role configuration.
   * @async
   */
  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await _addUserRoleConfiguration(userRoleConfig);
      if (res) {
        setLoading(false);
        const message = "Data Saved Successfully !";
        handleCloseSnackBar({
          show: true,
          severity: "success",
          message: message,
        });
        getUserRoleConfiguration();
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user role configuration when the component mounts or OrganizationId change
  useEffect(() => {
    getUserRoleConfiguration();
  }, [OrganizationId]);

  return (
    <>
      <OrganizationTitle />

      <Card sx={{ mb: 3, p: 2 }}>
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2 }}
            data-testid="userRoleConfigTitle"
          >
            User Role Configuration
          </Typography>

          <Grid container spacing={2}>
            {/* Admin Count */}
            <Grid item xs={6} sm={4} md={3}>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "light", mb: 1 }}
                data-testid="adminsLabel"
              >
                Number of Admins
              </Typography>
              <TextField
                required
                name="numberOfAdmins"
                size="small"
                fullWidth
                inputProps={{
                  "data-testid": "numberOfAdminsValue",
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                value={userRoleConfig.numberOfAdmins}
                onChange={handleChange}
              />
            </Grid>

            {/* Standard Users Count */}
            <Grid item xs={6} sm={4} md={3}>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "light", mb: 1 }}
                data-testid="standardUsersLabel"
              >
                Number of Standard Users
              </Typography>
              <TextField
                required
                name="numberOfStandardUsers"
                size="small"
                fullWidth
                inputProps={{
                  "data-testid": "numberOfStandardUsersValue",
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                value={userRoleConfig.numberOfStandardUsers}
                onChange={handleChange}
              />
            </Grid>

            {/* Viewers Count */}
            <Grid item xs={6} sm={4} md={3}>
              <Typography
                variant="body2"
                sx={{ fontSize: 14, fontWeight: "light", mb: 1 }}
                data-testid="viewersLabel"
              >
                Number of Viewers
              </Typography>
              <TextField
                required
                name="numberOfViewers"
                size="small"
                fullWidth
                inputProps={{
                  "data-testid": "numberOfViewersValue",
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                value={userRoleConfig.numberOfViewers}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <LoadingAndSnackbar
        loading={loading}
        success={success}
        handleCloseSnackBar={handleCloseSnackBar}
        handleSave={handleSave}
      />
    </>
  );
};

export default UserRole;
