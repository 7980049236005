import { ChangeEvent, useState } from "react";
import { Grid, Typography, TextField, Stack, debounce } from "@mui/material";
import SingleDropDown from "components/Dropdowns/SingleDropDown/SingleDropDown";
import TitleDefinition from "components/TitleDefinition";
import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";

import {
  DataWarningMessages,
  ReductionMeasureLists,
  TitleDefinitionConstants,
} from "constants/constant";
import Blurb from "components/Blurb/Blurb";

export default function MeasureTiming(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
) {
  const [paceWarning, setPaceWarning] = useState(false);
  const [executionWarning, setExecutionWarning] = useState(false);
  const [techLifeWarning, setTechLifeWarning] = useState(false);
  const [financialLifeWarning, setFinancialLifeWarning] = useState(false);

  const fieldRangeValidater = debounce(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      min: number,
      max: number
    ) => {
      const isOutOfBound = () => {
        if (
          event.target.value &&
          (+event.target.value < min || +event.target.value > max)
        ) {
          return true;
        } else return false;
      };
      switch (event.target.name) {
        case "pace":
          isOutOfBound() ? setPaceWarning(true) : setPaceWarning(false);
          break;
        case "execution":
          isOutOfBound()
            ? setExecutionWarning(true)
            : setExecutionWarning(false);
          break;
        case "techLife":
          isOutOfBound() ? setTechLifeWarning(true) : setTechLifeWarning(false);
          break;
        case "financialLifetime":
          isOutOfBound()
            ? setFinancialLifeWarning(true)
            : setFinancialLifeWarning(false);
          break;
      }
    },
    300
  );
  
  return (
    <Grid>
      <Typography
        variant="h6"
        data-testid={"measureReduction"}
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Measure Timing
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"startYearLabel"}
              sx={{ mb: 2 }}
            >
              Start Year
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.StartYear}
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            required
            onChange={props.fieldChangeHandler}
            list={ReductionMeasureLists.StartYearList}
            value={
              props.draftReductionMeasure.startYear === undefined ||
              props.draftReductionMeasure.startYear < 1
                ? ""
                : props.draftReductionMeasure.startYear?.toString()
            }
            name={"startYear"}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"endYearLabel"}
              sx={{ mb: 2 }}
            >
              End Year
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.EndYear}
              placement="auto"
            />
          </Stack>
          <SingleDropDown
            disabled={
              (props.draftReductionMeasure.startYear ?? 0) <
              ReductionMeasureLists.StartYearList[0]
            }
            required
            onChange={props.fieldChangeHandler}
            list={ReductionMeasureLists.EndYearList.filter(
              (y) => y >= (props.draftReductionMeasure.startYear ?? 0)
            )}
            value={
              props.draftReductionMeasure.endYear === undefined ||
              props.draftReductionMeasure.endYear < 1
                ? ""
                : props.draftReductionMeasure.endYear?.toString()
            }
            name={"endYear"}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            data-testid={"startQuarterLabel"}
            sx={{ mb: 2 }}
          >
            Start Quarter
            <Typography component="span" sx={{ color: "red" }}>
              *
            </Typography>
          </Typography>
          <SingleDropDown
            required
            onChange={props.fieldChangeHandler}
            list={props.quarterList}
            value={
              props.draftReductionMeasure.startQuarter === undefined
                ? ""
                : props.draftReductionMeasure.startQuarter?.toString()
            }
            name={"startQuarter"}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="body1"
            data-testid={"endQuarterLabel"}
            sx={{ mb: 2 }}
          >
            End Quarter
            <Typography component="span" sx={{ color: "red" }}>
              *
            </Typography>
          </Typography>
          <SingleDropDown
            required
            onChange={props.fieldChangeHandler}
            list={props.quarterList}
            value={
              props.draftReductionMeasure.endQuarter === undefined
                ? ""
                : props.draftReductionMeasure.endQuarter?.toString()
            }
            name={"endQuarter"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"paceLabel"}>
              Pace (% implemented per year)
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.Pace}
              placement="auto"
            />
          </Stack>
          <TextField
            required
            type="number"
            id="pace"
            inputProps={{
              min: 0,
              max: 100.0,
              inputMode: "decimal",
              "data-testid": "pace",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"pace"}
            value={props.draftReductionMeasure.pace}
            onChange={(e) => {
              props.fieldChangeHandler(e);
              fieldRangeValidater(e, 10, 100);
            }}
          />
          {paceWarning && (
            <Blurb
              isClosable
              infoText={DataWarningMessages.ReductionMeasure.Percentage1}
            />
          )}
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"executionLabel"}>
              Execution (% of potential reduction achieved)
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.Execution}
              placement="auto"
            />
          </Stack>
          <TextField
            required
            type="number"
            id="execution"
            inputProps={{
              min: 0,
              max: 100.0,
              inputMode: "decimal",
              "data-testid": "execution",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"execution"}
            value={props.draftReductionMeasure.execution}
            onChange={(e) => {
              props.fieldChangeHandler(e);
              fieldRangeValidater(e, 10, 100);
            }}
          />
          {executionWarning && (
            <Blurb
              isClosable
              infoText={DataWarningMessages.ReductionMeasure.Percentage1}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"techLifeLabel"}>
              Tech Life
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.TechnicalLifetime
              }
              placement="auto"
            />
          </Stack>
          <TextField
            type="number"
            id="techLife"
            inputProps={{
              min: 0,
              max: 100,
              "data-testid": "techLife",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"techLife"}
            value={props.draftReductionMeasure.techLife ?? ""}
            onChange={(e) => {
              props.fieldChangeHandler(e);
              fieldRangeValidater(e, 0, 30);
            }}
          />
          {techLifeWarning && (
            <Blurb
              isClosable
              infoText={DataWarningMessages.ReductionMeasure.LifeTime1}
            />
          )}
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"financialLabel"}>
              Financial Lifetime
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.FinancialLifetime
              }
              placement="auto"
            />
          </Stack>
          <TextField
            type="number"
            id="financialLifeTime"
            inputProps={{
              min: 0,
              max: 100.0,
              "data-testid": "financialLifeTime",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"financialLifetime"}
            value={props.draftReductionMeasure.financialLifetime?? ""}
            onChange={(e) => {
              props.fieldChangeHandler(e);
              fieldRangeValidater(e, 0, 30);
            }}
          />
          {financialLifeWarning && (
            <Blurb
              isClosable
              infoText={DataWarningMessages.ReductionMeasure.LifeTime1}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="body1" data-testid={"notesLabel"}>
            Notes
          </Typography>
          <TextField
            id="measureTimingNotes"
            multiline
            rows={2}
            inputProps={{
              flexWrap: "wrap",
              maxLength: 300,
              "data-testid": "measureTimingNotes",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"measureTimingNotes"}
            value={props.draftReductionMeasure.measureTimingNotes}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
