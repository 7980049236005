//Navigation Drawer
const drawerWidth = 240;
const rightSliderWidth = "40%";

const REFRESH = 0;

// Left Drawer Width
const LeftDrawerWidth = 280;


export class Constants {
  static readonly SessionStorageKey_Token = "Token";
  static readonly SessionStorageKey_TokenExpirationTime = "TokenExpiration";
  static readonly SessionStorageKey_UserAccount = "UserAccount";
  static readonly BaseYear = 2020;
  static readonly TargetYear = 2030;
  static readonly DefaultCountry="Global";
  static readonly DefaultRegion="Global";
}
enum MCA_Ease {
  NoRating = "No rating",
  VeryHigh = "Very High",
  High = "High",
  Moderate = "Moderate",
  Low = "Low",
  VeryLow = "Very Low",
}

enum MCA_Reputation {
  NoRating = "No rating",
  VeryNegativeImpact = "Very Negative Impact",
  ModerateNegativeImpact = "Moderate Negative Impact",
  NoImpact = "No Impact",
  ModeratelyPositiveImpact = "Moderately Positive Impact",
  VeryPositiveImpact = "Very Positive Impact",
}

enum MCA_ClimateRisk {
  NoRating = "No rating",
  VeryHigh = "Very High",
  High = "High",
  Moderate = "Moderate",
  Low = "Low",
  VeryLow = "Very Low",
}

enum MCA_Biodiversity {
  NoRating = "No rating",
  VeryHarmful = "Very Harmful",
  PotentiallyHarmful = "Potentially Harmful",
  MitigationAvailable = "Mitigation Available",
  NoImpactNeutral = "No Impact / Neutral",
  EnhancesBiodiversity = "Enhances Biodiversity",
}

enum MCA_Community {
  NoRating = "No rating",
  VeryNegativeImpact = "Very Negative Impact",
  ModerateNegativeImpact = "Moderate Negative Impact",
  NoImpact = "No Impact",
  ModeratelyPositiveImpact = "Moderately Positive Impact",
  VeryPositiveImpact = "Very Positive Impact",
}

enum MCA_TechnologyReadiness {
  NoRating = "No rating",
  RAndD = "R&D",
  Demonstration = "Demonstration",
  Pilot = "Pilot",
  CommerciallyAvailableLimited = "Commercially Available - Limited",
  CommerciallyAvailableFull = "Commercially Available - Full",
}

enum MCA_OperationalDisruption {
  NoRating = "No rating",
  VerySignificantDisruption = "Very Significant Disruption",
  SignificantDisruption = "Significant Disruption",
  SomeDisruption = "Some Disruption",
  MinimalDisruption = "Minimal Disruption",
  NoDisruption = "No Disruption",
}

enum MCA_HealthAndSafety {
  NoRating = "No rating",
  VeryNegativeImpact = "Very Negative Impact",
  ModerateNegativeImpact = "Moderate Negative Impact",
  NoImpact = "No Impact",
  ModeratelyPositiveImpact = "Moderately Positive Impact",
  VeryPositiveImpact = "Very Positive Impact",
}

export {
  drawerWidth,
  rightSliderWidth,
  LeftDrawerWidth,
  REFRESH,
  MCA_Ease,
  MCA_Reputation,
  MCA_ClimateRisk,
  MCA_Biodiversity,
  MCA_Community,
  MCA_TechnologyReadiness,
  MCA_OperationalDisruption,
  MCA_HealthAndSafety,
};

export class MasterPropertyCategories {
  public static readonly Organization = "Organization";
  public static readonly ReductionMeasure = "ReductionMeasure";
  public static readonly EnergyType = "EnergyType";
  public static readonly GHGCategory = "GHGCategory";
  public static readonly MeasureType = "MeasureType";
}
export class MasterPropertyType {
  public static readonly Organization = {
    Industry: "Industry",
    AnnualRevenueRange: "AnnualRevenueRange",
    NoOfEmployeesRange: "NoOfEmployeesRange",
    GHGScope: "GHGScope",
  };
  public static readonly ReductionMeasure = {
    MeasureCategory: "MeasureCategory",
    MeasureType: "MeasureType",
    EnergyCategory: "EnergyCategory",
    MCAImplementationComplexity: "MCAImplementationComplexity",
    MCAReputation: "MCAReputation",
    MCAClimateRisk: "MCAClimateRisk",
    MCABiodiversity: "MCABiodiversity",
    MCACommunity: "MCACommunity",
    MCAHealthSafety: "MCAHealthSafety",
    MCAOperationalDisruption: "MCAOperationalDisruption",
    MeasureStage: "MeasureStage",
    GHGCategory: "GHGCategory",
    MCATechRediness: "MCATechRediness",
    Quarter: "Quarter",
  };
}

export class ReductionMeasureLists {
  public static readonly EmissionTypeList = ["Energy", "GHG Emission"];
  public static readonly StartYearList = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
    2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038,
    2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050,2051
  ];
  public static readonly EndYearList = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
    2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038,
    2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050,2051
  ];
  public static readonly NoOfCarriers = [1, 2, 3];
}

export class TitleDefinitionConstants {
  static readonly ReductionMeasure = {
    DataSources:
      "Use this field to describe how emission reductions are being calculated and measured from implementation of this measure.",
    Assumptions:
      "Use this field to describe any assumptions within the emission reduction calculation methodology. Include any audits or estimates are assumed.",
    Stage:
      "Choose from the dropdown list which stage of implementation this measure is currently at.",
    GHGScope:
      "Choose from the dropdown list which scope of emissions this measure impacts.",
    GHGCategory:
      "Choose from the dropdown list which category of emissions this measure impacts.",
    MeasureCategory:
      "Choose from the dropdown list which category best describes the measure.",
    MeasureType:
      "Choose from the dropdown list which type best describes the measure.",
    EnergyGHGReduction:
      "Choose from the dropdown list whether the reduction will be calculated based on a reduction in energy units (e.g., MWh) or GHG emissions.",
    NumberofCarriers:
      "Choose the number of carriers from the dropdown. A carrier is a substance (fuel) or system (electricity grid) that heat, power, or otherwise drive equipment and processes. It is used here to indicate how many types of energy or GHG emissions are involved in the measure. Many measures will only have one carrier, such as a reduction in MWh of electricity used when new lightbulbs are installed. Some will have more than one carrier, like when the transition to electric vehicles reduces fuel use (carrier 1) and increases electricity use (carrier 2).",
    EnergyCategory:
      "Choose from the dropdown list which type best describes the energy category.",
    EnergyUnits:
      "Choose from the dropdown list which unit of energy the measure will be measured in.",
    StartYear:
      "The Start Year is the year in which the measure is epected to begin implementation (Investigation Stage)",
    EndYear:
      "The End Year is the year in which the measure is expected to be fully implemented.",
    Pace: "The % of the measure implemented each year. A value of 100% (entered as “100”) will lead to the measure being fully implemented in the start year. Values <100% will lead to the measure being implemented evenly across multiple years. For example, entering “20” yields a 20% implementation pace, which results in the measure being full implemented within 5 years.",
    Execution:
      "	% of the measure expected to be implemented over the lifetime of the project. Most cases, this will be 100%, enter <100% if you expect the measure not to be fully implemented. ",
    TechnicalLifetime:
      "The technical lifetime is the life expectancy (in years) of a measure before it must reasonably be replaced.",
    FinancialLifetime:
      "The financial lifetime is the depreciation time in years.",
    easeRating:
      "Choose from the dropdown list which implementation complexity MCA best represents the measure.",
    reputationRating:
      "Choose from the dropdown list which reputation MCA best represents the measure.",
    climateRiskRating:
      "Choose from the dropdown list which climate risk MCA best represents the measure.",
    biodiversityRating:
      "Choose from the dropdown list which biodiversity MCA best represents the measure.",
    communityRating:
      "Choose from the dropdown list which community MCA best represents the measure.",
    technologyRedinessRating:
      "Choose from the dropdown list which technology MCA best represents the measure.",
    operationalDisruptionRating:
      "Choose from the dropdown list which operational disruption MCA best represents the measure.",
    healthSafetyRating:
      "Choose from the dropdown list which health and safety MCA best represents the measure.",
    CapExInvestment:
      "If you are not including financing costs as cash flow entries, then CapEx (Capital Expenditure) Investment represents the total investment cost. If you plan on financing costs in the cash flow calculations of the NPV, it represents the share of investment cost that is financed through your own (liquid) equity. (See Explanation of Financial Calculations)",
    FinancingCost:
      "Financing costs are annual additional costs that are related to debt financing. This value remains constant over the complete ‘financing term’ period, and thus represents annuity payment (which is comprised of interest and principal payment). ",
    FinancingTerm:
      "Total length or period for which downpayments are required to pay back debt obligation. ",
    OpEx: "	The effective change in annual operational expenditure (OPEX) costs due to implementation of the corresponding measure, excluding personnel. When a measure increases the yearly OPEX, this value is positive. If the annual OPEX costs decrease, the net change is negative, which translates into positive cashflow change.",
    Revenue:
      "The change in annual Operational Expenditure due to implementation of the measure. Positive values indicate a net cost. Negative values indicate a net savings",
    PersonnelTime:
      "Effective change in annual manhours that are required internally due to implementation of a measure. This effects the total annual OPEX. When a measure leads to a reduction of required labor hours, this value will be negative. (i.e. due to automatization)",
    PersonnelRate: "Average salary cost for an hour of labor",
    AnnualEnergyReduction :"To reflect an increase in energy use, enter a negative value, such as “-1000”.",
    AnnualGHGReduction : "To reflect an increase in GHG emissions, enter a negative value, such as “-1000”.",
    EnergyType : `Choose Grey Electricity when reducing Grey Electricity (or replacing it with Green Electricity) . 
    Choose Green Electricity if an increase in electricity use (e.g., from electrification) will be covered by Green Electricity (zero emissions).
    If you choose Green Electricity, the emissions change will be ZERO. 
                  `

  };
  static readonly PurchasedGoodsAndServices = "To be defined";
  static readonly BaseYear =
    "The GHG Protocol requires companies to select a base year with which to compare current emissions in a meaningful and consistent way. The base year is used for setting and tracking progress towards a GHG target. ";
  static readonly TargetYear =
    "The target year is the year in which your organization aims to achieve the stated GHG reduction. Emissions in the target year are compared to emissions in the base year to determine if the target was achieved.";
  static readonly ScopesCovered =
    "Select which scope of emissions your target includes.";
  static readonly Scope3CategoriesCovered =
    "If your target includes scope 3, select which categories your target includes.";
  static readonly ScopeReductionold =
    "Provide a numeric value between 0-100 that represents the percent target reduction when comparing emissions in the target year to emissions from the base year (by scope/category). ";
  static readonly ScopeReduction =
    "Enter the reduction percent in positive whole numbers. For example, a target to reduce emissions by 50% should be entered as “50”.";
  static readonly ScopeReductionNote = `If you have a GHG reduction target that is combined across multiple scopes, you may enter the same percent reduction in each scope. For all fields, enter the reduction percent in positive whole numbers. For example, a target to reduce emissions by 50% should be entered as “50”`;
  static readonly ScenarioViewInfo =`To update the Execution and Pace values for any measure in Scenario A, go to the "Reduction Measures" tab on the left panel. Select the desired measure, adjust the values, and click "Save." `
  static readonly OrganizationDetails = {
    Industry:
      "Select the industry that best represents your organization. Industry list is from the International Standard of Industrial Classification (ISIC) code list.",
    Currency: "Select the appropriate currency",
    AnnualRevenue:
      "Select the revenue range your company falls into. This is for demographic purposes.",
    NumberOfEmployees:
      "Select the range of employees that your company falls into, measured by full time equivalents (FTE). This is for demographic purposes.",
    NumberOfBusinessUnits:
      "Select the number of business units in the organization.",
    BaselineGHGEmissions:
      "The GHG Protocol requires companies to select a base year with which to compare current emissions in a meaningful and consistent way. The base year is used for setting and tracking progress towards a GHG target. Baseline emissions are your organization's GHG emissions in the base year, as defined by your target. If you do not have a target, choose the historical year to which you compare your emissions over time, or the most recent year for which GHG emissions have been calculated.",
    BaselineScopeEmissionNote:
      "Enter the GHG emission in the baseline year of your target. Target details, including the baseline year, will be entered in the next section.",
    BaselineScope1:
      "Use this field to enter the scope 1 emissions for the baseline reference year. Scope 1 covers emissions from sources that your company owns or controls directly. For example, burning fuel from company owned vehicle fleets, building on-site fossil fuel use (space or water heating, natural gas kitchens), and refrigerant leakage. ",
    BaselineScope2:
      "Use this field to enter the scope 2 emissions for the baseline reference year. Scope 2 covers emissions that your company causes indirectly and come from where the energy you purchase and use is produced. This occurs through the use of purchased electricity, steam, heat, or cooling. ",
    BaselineScope3:
      "This is a calculated field. Enter scope 3 emissions by category below to calculate total scope 3. Scope 3 covers emissions that are the result of activities from assets not owned or controlled by your company, but that your company indirectly affects its value chain. The GHG Protocol defines 15 categories of scope 3 emissions, though not every category will be relevant to your company. ",
    BaselineScope3Category1:
      "Use this field to enter the scope 3 emissions corresponding to Category 1 - Purchased Goods and Services for the baseline reference year. This category includes all upstream (i.e., cradle-to-gate) emissions from the production of products purchased or acquired by your company in the baseline reference year. ",
    BaselineScope3Category2:
      "Use this field to enter the scope 3 emissions corresponding to Category 2 - Capital Goods for the baseline reference year. This category includes all upstream (i.e., cradle-to-gate) emissions from the production of capital goods purchased or acquired by your company in the baseline reference year. Capital goods are final products that have an extended life and are used by the company to manufacture a product,  provide a service, or sell, store, and deliver merchandise. In financial accounting, capital goods are treated as fixed assets or as plant, property, and equipment (PP&E). Examples of capital goods include equipment, machinery, buildings, facilities, and vehicles.",
    BaselineScope3Category3:
      "Use this field to enter the scope 3 emissions corresponding to Category 3 - Fuel- and Energy-Related Activities for the baseline reference year. This category includes emissions related to the production of fuels and energy purchased and consumed by your company in the baseline reference year that are not included in scope 1 or scope 2. These activities include the upstream emissions of purchased fuels and purchased electricity, transmission and distribution (T&D) losses, and generation of purchased electricity that is sold to end users.",
    BaselineScope3Category4:
      "Use this field to enter the scope 3 emissions corresponding to Category 4 - Upstream Transportation & Distribution for the baseline reference year. This category includes emissions from the transportation and distribution of products (excluding fuel and energy products) purchased or acquired by your company in the reporting year in vehicles and facilities not owned or operated by your company, as well as other transportation and distribution services purchased by your company in the reporting year (including both inbound and outbound logistics).",
    BaselineScope3Category5:
      "Use this field to enter the scope 3 emissions corresponding to Category 5 - Waste Generated in Operations for the baseline reference year. This category includes emissions from third-party disposal and treatment of waste that is generated in your company’s owned or controlled operations in the reporting year. This category includes emissions from disposal of both solid waste and wastewater. Only waste treatment in facilities owned or operated by third parties is included in scope 3. Waste treatment at facilities owned or controlled by your company is accounted for in scope 1 and scope 2.",
    BaselineScope3Category6:
      "Use this field to enter the scope 3 emissions corresponding to Category 6 - Business Travel for the baseline reference year. This category includes emissions from the transportation of employees for business-related activities in vehicles owned or operated by third parties, such as aircraft, trains, buses, and passenger cars. Emissions from transportation of employees to and from work are accounted for in scope 3, category 7 (Employee commuting).",
    BaselineScope3Category7:
      "Use this field to enter the scope 3 emissions corresponding to Category 7 - Employee Commuting for the baseline reference year. This category includes emissions from the transportation of employees between their homes and their worksites. Emissions from employee commuting may arise from automobiles, buses, rail, air, and other modes of transportation. Emissions from teleworking (i.e., employees working remotely) may be included in this category.",
    BaselineScope3Category8:
      "Use this field to enter the scope 3 emissions corresponding to Category 8 - Upstream Leased Assets for the baseline reference year. This category includes emissions from the operation of assets that are leased by the reporting company in the reporting year and not already included in your company’s scope 1 or scope 2 inventories (i.e., transportation vehicles, buildings, machinery, etc.). This category is only applicable to companies that operate leased assets (i.e., lessees). For companies that own and lease assets to others (i.e., lessors), see category 13 (Downstream leased assets).",
    BaselineScope3Category9:
      "Use this field to enter the scope 3 emissions corresponding to Category 9 - Downstream Transportation & Distribution for the baseline reference year. This category includes emissions from transportation and distribution of products sold by your company in the reporting year between your company’s operations and the end consumer (if not paid for by your company), in vehicles and facilities not owned or controlled by your company. This category includes emissions from retail and storage.",
    BaselineScope3Category10:
      "Use this field to enter the scope 3 emissions corresponding to Category 10 - Processing of Sold Products for the baseline reference year. This category includes emissions from processing of sold intermediate products by third parties (e.g., manufacturers) subsequent to sale by your company. Intermediate products are products that require further processing, transformation, or inclusion in another product before use, and therefore result in emissions from processing subsequent to sale by your company and before use by the end consumer. Emissions from processing should be allocated to the intermediate product.",
    BaselineScope3Category11:
      "Use this field to enter the scope 3 emissions corresponding to Category 11 - Use of Sold Products for the baseline reference year. This category includes emissions from the use of goods and services sold by your company in the reporting year. Your company’s scope 3 emissions from use of sold products include the scope 1 and scope 2 emissions of end users. End users include both consumers and business customers that use final products.",
    BaselineScope3Category12:
      "Use this field to enter the scope 3 emissions corresponding to Category 12 - End-of-Life Treatment of Sold Products for the baseline reference year. This category includes emissions from the waste disposal and treatment of products sold by your company (in the reporting year) at the end of their life.",
    BaselineScope3Category13:
      "Use this field to enter the scope 3 emissions corresponding to Category 13 - Downsteam Leased Assets for the baseline reference year. This category includes emissions from the operation of assets that are owned by your company (acting as lessor) and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. This category is applicable to lessors (i.e., companies that receive payments from lessees).",
    BaselineScope3Category14:
      "Use this field to enter the scope 3 emissions corresponding to Category 14 - Franchises for the baseline reference year. This category includes emissions from the operation of franchises not included in scope 1 or scope 2. A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location.",
    BaselineScope3Category15:
      "Use this field to enter the scope 3 emissions corresponding to Category 15 - Investments for the baseline reference year. This category includes emissions from your company’s investments in the reporting year, not already included in scope 1 or scope 2. This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) and companies that provide financial services.",
    BaselineScope3Category16:
      "Other upstream emissions refers to any upstream scope 3 emissions sources that are not inluded in other categories. This could include indirect scope 3 emissions or optional scope 3 emissions.",
    BaselineScope3Category17:
      "Other downstream emissions refers to any downstream scope 3 emissions sources that are not inluded in other categories. This could include indirect scope 3 emissions or optional scope 3 emissions.",
  };

  static readonly redutionMeasurenoRowsLabel="There are currently no measures available. To create a new measure, please click on 'Add Measure' and provide the required details."
  static readonly priceEmptyMessage="There are no measures created for this organization. Please create atleast one measure to view the prices data.";
  static readonly ghgTargetRowsLabel="There are currently no targets available. To create a new target, please click on 'Add New Target' and provide the required details."
  static readonly userMgmtDefaultText="There are currently no user added. To add a new user, please click on 'Add User' and provide the required details."
}

export class ConfirmationMessages {
  static readonly ghgConfirmation =
    "Deleting the Target name will remove all associated data. Please ensure that you have reviewed information related to this target before proceeding.";
  static readonly orgConfirmation =
    "Deleting the organization will remove all associated data. Please ensure that you have reviewed information related to this organization before proceeding.";

  static readonly UserConfirmation =
    "Deleting the user role will remove all associated data. Please ensure that you have reviewed information related to this user before proceeding.";

  static readonly AssignOrgConfirmation =
    "Deleting the Assign organization will remove all associated data. Please ensure that you have reviewed information related to this user before proceeding.";

  static readonly reductionMeasureConfirmation =
    "Deleting the reduction measure will remove all associated data. Please ensure that you have reviewed information related to this user before proceeding.";
}

export class Currency {
  private static createCurrency(text: string, value: string, symbol: string) {
    return { text, value, symbol };
  }

  static readonly currency = [
    Currency.createCurrency("Pound Sterling", "GBP", "£"),
    Currency.createCurrency("Euro", "EUR", "€"),
    Currency.createCurrency("US Dollar", "USD", "$"),
    Currency.createCurrency("Australian Dollar", "AUD", "AU$"),
    Currency.createCurrency("Brazilian Real", "BRL", "R$"),
    Currency.createCurrency("Canadian Dollar", "CAD", "CA$"),
    Currency.createCurrency("Chinese Renminbi", "CNH", "CN¥"),
    Currency.createCurrency("Hong Kong Dollar", "HKD", "HK$"),
    Currency.createCurrency("Japanese Yen", "JPY", "JP¥"),
    Currency.createCurrency("New Zealand Dollar", "NZD", "NZ$"),
    Currency.createCurrency("Swiss Franc", "CHF", "SFr."),
  ];
}


export class UserRoles {
  static readonly SuperAdmin = "Super Admin";
  static readonly Admin = "Admin";
  static readonly StandardUser = "Standard User";
  static readonly Viewers = "Viewers";
}

export class DataWarningMessages {
  static readonly ReductionMeasure = {
    Percentage1:
      "You have entered a value outside of the expected range of 10% to 100%. Values <10% imply that the full measure will take more than 10 years to implement. Please review your response before saving.",
    LifeTime1:
      "You have entered a value outside of the expected range of 1 to 30 years. Please review your response before saving.",
  };
}

export class ProjectRegisterMetrics {
  static readonly MarginalAbatementCostCurve = "Marginal Abatement Cost";
  static readonly TimelineandFinancialMetrics = "Timeline and Financial Metrics";
  static readonly MeasureImpactsbyScope = "Measure Impacts by Scope";
  static readonly InteractiveGlidepath = "Interactive Glidepath";
}
