import React from "react";
import { Grid, Typography, TextField, Stack } from "@mui/material";
import SingleDropDown from "components/Dropdowns/SingleDropDown/SingleDropDown";
import TitleDefinition from "components/TitleDefinition";
import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";
import { getEnergyUnits } from "./data/utils";
import {
    ReductionMeasureLists,
    TitleDefinitionConstants,
  } from "constants/constant";

export default function MeasureReduction(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
) {
  return (
    <>
      <Typography
          variant="h6"
          data-testid={"measureReduction"}
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Measure Reduction
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"ghgReductionLabel"}
                sx={{ mb: 2 }}
              >
                Energy or GHG Reduction
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.ReductionMeasure.EnergyGHGReduction
                }
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              required
              onChange={props.fieldChangeHandler}
              list={ReductionMeasureLists.EmissionTypeList}
              value={props.draftReductionMeasure.ghgReduction}
              name={"ghgReduction"}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Stack direction={"row"}>
              <Typography
                variant="body1"
                data-testid={"noOfCarrierLabel"}
                sx={{ mb: 2 }}
              >
                Number of Carriers
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TitleDefinition
                content={
                  TitleDefinitionConstants.ReductionMeasure.NumberofCarriers
                }
                placement="auto"
              />
            </Stack>
            <SingleDropDown
              required
              onChange={props.fieldChangeHandler}
              list={ReductionMeasureLists.NoOfCarriers}
              value={props.draftReductionMeasure.noOfCarrier?.toString()}
              name={"noOfCarrier"}
            />
          </Grid>
          {props.draftReductionMeasure.ghgCarriers?.map((Carrier, i) => {
            return (
              <React.Fragment key={Carrier.id}>
                <Grid item xs={11}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      color: "#718096",
                    }}
                    data-testid={"GHGCarrier" + (i + 1)}
                  >
                    GHG Carrier {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>
                  <Stack direction={"row"}>
                    <Typography
                      variant="body1"
                      data-testid={"ghgScopeLabel"}
                      sx={{ mb: 2 }}
                    >
                      GHG Scope
                      <Typography component="span" sx={{ color: "red" }}>
                        *
                      </Typography>
                    </Typography>
                    <TitleDefinition
                      content={
                        TitleDefinitionConstants.ReductionMeasure.GHGScope
                      }
                      placement="auto"
                    />
                  </Stack>
                  <SingleDropDown
                    required
                    onChange={(e) => {
                      props.ghgCarrierfieldChangeHandler(e, i);
                    }}
                    list={props.gHGScopeList.map((g) => g.value)}
                    value={Carrier.ghgScope}
                    name={"ghgScope"}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>
                  <Stack direction={"row"}>
                    <Typography
                      variant="body1"
                      data-testid={"ghgCategoryLabel"}
                      sx={{ mb: 2 }}
                    >
                      GHG Category
                      <Typography component="span" sx={{ color: "red" }}>
                        *
                      </Typography>
                    </Typography>
                    <TitleDefinition
                      content={
                        TitleDefinitionConstants.ReductionMeasure.GHGCategory
                      }
                      placement="auto"
                    />
                  </Stack>
                  <SingleDropDown
                    disabled={!Carrier.ghgScope}
                    required
                    onChange={(e) => {
                      props.ghgCarrierfieldChangeHandler(e, i);
                    }}
                    list={props.gHGCategoryList
                      .filter(
                        (gc) => gc.type === Carrier.ghgScope?.replace(" ", "")
                      )
                      .map((g) => g.value)}
                    value={Carrier.ghgCategory}
                    name={"ghgCategory"}
                  />
                </Grid>
                {props.draftReductionMeasure.ghgReduction ===
                ReductionMeasureLists.EmissionTypeList[0] ? (
                  <React.Fragment>
                    <Grid item xs={6} sx={{ mt: 1 }}>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body1"
                          data-testid={"energyCategoryLabel" + (i + 1)}
                          sx={{ mb: 2 }}
                        >
                          Energy Category
                          <Typography component="span" sx={{ color: "red" }}>
                            *
                          </Typography>
                        </Typography>
                        <TitleDefinition
                          content={
                            TitleDefinitionConstants.ReductionMeasure
                              .EnergyCategory
                          }
                          placement="auto"
                        />
                      </Stack>
                      <SingleDropDown
                        required
                        onChange={(e) => {
                          props.ghgCarrierfieldChangeHandler(e, i);
                        }}
                        list={props.energyCategoryList.map((g) => g.value)}
                        value={Carrier.energyCategory}
                        name={"energyCategory"}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 1 }}>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body1"
                          data-testid={"energyTypeLabel" + (i + 1)}
                          sx={{ mb: 2 }}
                        >
                          Energy Type
                          <Typography component="span" sx={{ color: "red" }}>
                            *
                          </Typography>
                        </Typography>
                        {Carrier.energyCategory === "Electricity" && (
                          <TitleDefinition
                            content={
                              TitleDefinitionConstants.ReductionMeasure
                                .EnergyType
                            }
                            placement="auto"
                          />
                        )}
                      </Stack>

                      <SingleDropDown
                        required
                        onChange={(e) => {
                          props.ghgCarrierfieldChangeHandler(e, i);
                        }}
                        list={props.energyTypeList
                          .filter((e) => e.type === Carrier.energyCategory)
                          .map((g) => g.value)}
                        value={
                          Carrier.energyType &&
                          props.energyTypeList
                            .filter((e) => e.type === Carrier.energyCategory)
                            .map((g) => g.value)
                            .includes(Carrier.energyType)
                            ? Carrier.energyType
                            : ""
                        }
                        name={"energyType"}
                      />
                    </Grid>
                    {Carrier.ghgScope === "Scope 2" &&
                      Carrier.ghgCategory === "Electricity" && (
                        <>
                          <Grid item xs={6} sx={{ mt: 1 }}>
                            <Stack direction={"row"}>
                              <Typography
                                variant="body1"
                                data-testid={"countryLabel" + (i + 1)}
                                sx={{ mb: 2 }}
                              >
                                Country
                                <Typography
                                  component="span"
                                  sx={{ color: "red" }}
                                >
                                  *
                                </Typography>
                              </Typography>
                            </Stack>
                            <SingleDropDown
                              required
                              onChange={(e) => {
                                props.ghgCarrierfieldChangeHandler(e, i);
                              }}
                              list={Array.from(
                                new Set(props.countryList.map((c) => c.country))
                              )}
                              value={Carrier.country}
                              name="country"
                            />
                          </Grid>
                          <Grid item xs={6} sx={{ mt: 1 }}>
                            <Typography
                              variant="body1"
                              data-testid={"regionLabel" + (i + 1)}
                              sx={{ mb: 2 }}
                            >
                              Region
                              <Typography
                                component="span"
                                sx={{ color: "red" }}
                              >
                                *
                              </Typography>
                            </Typography>
                            <SingleDropDown
                              required
                              onChange={(e) => {
                                props.ghgCarrierfieldChangeHandler(e, i);
                              }}
                              list={props.countryList
                                .filter((gc) => gc.country === Carrier.country)
                                .map((g) => g.region)}
                              value={Carrier.region}
                              name={"region"}
                            />
                          </Grid>
                        </>
                      )}

                    <Grid item xs={6} sx={{ mt: 1 }}>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body1"
                          data-testid={"EnergyUnits" + (i + 1)}
                          sx={{ mb: 2 }}
                        >
                          Energy Units
                        </Typography>
                        <TitleDefinition
                          content={
                            TitleDefinitionConstants.ReductionMeasure
                              .EnergyUnits
                          }
                          placement="auto"
                        />
                      </Stack>
                      <SingleDropDown
                        disabled={
                          !(Carrier.energyType && Carrier.energyType.length > 0)
                        }
                        required
                        onChange={(e) => {
                          props.ghgCarrierfieldChangeHandler(e, i);
                        }}
                        list={getEnergyUnits(
                          props.energyTypeList,
                          Carrier.energyType ?? ""
                        )}
                        value={
                          Carrier.energyUnits &&
                          getEnergyUnits(
                            props.energyTypeList,
                            Carrier.energyType ?? ""
                          ).includes(Carrier.energyUnits)
                            ? Carrier.energyUnits
                            : ""
                        }
                        name={"energyUnits"}
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ mt: 1 }}>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body1"
                          data-testid={"AnnualEnergyReductionLabel" + (i + 1)}
                        >
                          Annual Energy Reduction
                        </Typography>
                        <TitleDefinition
                          content={
                            TitleDefinitionConstants.ReductionMeasure
                              .AnnualEnergyReduction
                          }
                          placement="auto"
                        />
                      </Stack>

                      <TextField
                        id={"AnnualEnergyReduction" + (i + 1)}
                        inputProps={{
                          maxLength: 12,
                          "data-testid": "AnnualEnergyReduction" + (i + 1),
                        }}
                        fullWidth
                        margin="normal"
                        size="small"
                        name={"annualReduction"}
                        value={Carrier.annualReduction ?? ""}
                        onChange={(e) => {
                          props.ghgCarrierfieldChangeHandler(e, i);
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item xs={6} sx={{ mt: 1 }}>
                    <Stack direction={"row"}>
                      <Typography
                        variant="body1"
                        data-testid={"AnnualGHGReductionLabel" + (i + 1)}
                      >
                        Annual GHG Reduction (tCO₂e)
                      </Typography>
                      <TitleDefinition
                        content={
                          TitleDefinitionConstants.ReductionMeasure
                            .AnnualGHGReduction
                        }
                        placement="auto"
                      />
                    </Stack>

                    <TextField
                      id={"AnnualGHGReduction" + (i + 1)}
                      inputProps={{
                        maxLength: 12,
                        "data-testid": "AnnualGHGReduction" + (i + 1),
                      }}
                      fullWidth
                      margin="normal"
                      size="small"
                      name={"annualReduction"}
                      value={Carrier.annualReduction}
                      onChange={(e) => {
                        props.ghgCarrierfieldChangeHandler(e, i);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant="body1" data-testid={"Notes"}>
                    Notes
                  </Typography>
                  <TextField
                    id={"Notes" + (i + 1)}
                    multiline
                    rows={2}
                    inputProps={{
                      flexWrap: "wrap",
                      maxLength: 300,
                      "data-testid": "Notes" + (i + 1),
                    }}
                    fullWidth
                    margin="normal"
                    size="small"
                    name={"notes"}
                    value={Carrier.notes}
                    onChange={(e) => {
                      props.ghgCarrierfieldChangeHandler(e, i);
                    }}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
    </>
  );
}