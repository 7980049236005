import React from "react";
import { FormControl, Select, MenuItem,SelectChangeEvent } from "@mui/material";
import { ProjectRegisterOptions } from "pages/Dashboards/ProjectRegister/data/ProjectRegisterData";

interface ProjectRegisterDropdownProps {
  selectedOption: string;
  handleOptionChange: (event: SelectChangeEvent<string>) => void;
}

const ProjectRegisterDropdown: React.FC<ProjectRegisterDropdownProps> = ({ selectedOption, handleOptionChange }) => {
  return (
    <FormControl fullWidth>
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        sx={{ width: "320px" }}
        id="projectRegisterOptionsDrodpdown"
        data-testid="projectRegisterOptionsDrodpdown"
      >
        {ProjectRegisterOptions.map((option) => (
          <MenuItem key={option.id} value={option.value} sx={{ textAlign: "left" }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectRegisterDropdown;
