import { Grid, Card, CardContent, Typography, Divider } from "@mui/material";
import {
  MCA_Ease,
  MCA_Reputation,
  MCA_ClimateRisk,
  MCA_Biodiversity,
  MCA_Community,
  MCA_TechnologyReadiness,
  MCA_OperationalDisruption,
  MCA_HealthAndSafety,
  ReductionMeasureLists,
} from "constants/constant";
import { ReductionMeasureModel } from "object-models/ReductionMeasures";
import DisplayItem from "pages/OrganizationDetails/DisplayItem";
import React from "react";

interface ViewReductionMeasureProps {
  reductionMeasure: ReductionMeasureModel;
}

/**
 * Component to display detailed information of a reduction measure.
 *
 * @param props - The properties object.
 * @param props.reductionMeasure - The reduction measure data to display.
 * @returns A grid layout containing various details about the reduction measure.
 */
export default function ViewReductionMeasure(
  props: Readonly<ViewReductionMeasureProps>
) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 20,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                }}
                data-testid="MeasureDetails"
              >
                Measure Details
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Name"
                isHelperTextRequired={false}
                value={props.reductionMeasure.reductionMeasureName}
                dataTestIdlabel="NameLabel"
                dataTestIdvalue="NameValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Business Unit"
                isHelperTextRequired={false}
                value={props.reductionMeasure.businessUnit}
                dataTestIdlabel="BusinessUnitLabel"
                dataTestIdvalue="BusinessUnitValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Data Sources"
                isHelperTextRequired={false}
                value={props.reductionMeasure.dataSources}
                dataTestIdlabel="DataSourcesLabel"
                dataTestIdvalue="DataSourcesValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Stage"
                isHelperTextRequired={false}
                value={props.reductionMeasure.measureStage}
                dataTestIdlabel="StageLabel"
                dataTestIdvalue="StageValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Measure Category"
                isHelperTextRequired={false}
                value={props.reductionMeasure.measureCategory}
                dataTestIdlabel="MeasureCategoryLabel"
                dataTestIdvalue="MeasureCategoryValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Measure Type"
                isHelperTextRequired={false}
                value={props.reductionMeasure.measureType}
                dataTestIdlabel="MeasureTypeLabel"
                dataTestIdvalue="MeasureTypeValue"
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayItem
                name="Description"
                isHelperTextRequired={false}
                value={props.reductionMeasure.measureDescription}
                dataTestIdlabel="DescriptionLabel"
                dataTestIdvalue="DescriptionValue"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider data-testid="MeasureDetailsDivider" />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 18,
                  fontWeight: "600",
                  color: "#718096",
                }}
                data-testid="MeasureReduction"
              >
                Measure Reduction
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <DisplayItem
                name="Energy or GHG Reduction"
                isHelperTextRequired={false}
                value={props.reductionMeasure.ghgReduction}
                dataTestIdlabel="EnergyorGHGReductionLabel"
                dataTestIdvalue="EnergyorGHGReductionValue"
              />
            </Grid>

            <Grid item xs={4}>
              <DisplayItem
                name="Number of Carriers"
                isHelperTextRequired={false}
                value={
                  props.reductionMeasure.ghgCarriers === undefined
                    ? 0
                    : props.reductionMeasure.ghgCarriers?.length
                }
                dataTestIdlabel="NumberofCarriersLabel"
                dataTestIdvalue="NumberofCarriersValue"
              />
            </Grid>
            {props.reductionMeasure.ghgCarriers?.map((Carrier, i) => {
              return (
                <React.Fragment key={Carrier.id}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#718096",
                      }}
                      data-testid={"GHGCarrier" + (i + 1)}
                    >
                      GHG Carrier {i + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="GHG Scope"
                      isHelperTextRequired={false}
                      value={Carrier.ghgScope}
                      dataTestIdlabel="GHGScopeLabel"
                      dataTestIdvalue="GHGScopeValue"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayItem
                      name="GHG Category"
                      isHelperTextRequired={false}
                      value={Carrier.ghgCategory}
                      dataTestIdlabel="GHGCategoryLabel"
                      dataTestIdvalue="GHGCategoryValue"
                    />
                  </Grid>
                  {props.reductionMeasure.ghgReduction ===
                  ReductionMeasureLists.EmissionTypeList[0] ? (
                    <React.Fragment>
                      <Grid item xs={4}>
                        <DisplayItem
                          name="Energy Category"
                          isHelperTextRequired={false}
                          value={Carrier.energyCategory}
                          dataTestIdlabel="energyCategory"
                          dataTestIdvalue="energyCategory"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DisplayItem
                          name="Energy Type"
                          isHelperTextRequired={false}
                          value={Carrier.energyType}
                          dataTestIdlabel="energyType"
                          dataTestIdvalue="energyType"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DisplayItem
                          name="Energy Units"
                          isHelperTextRequired={false}
                          value={Carrier.energyUnits}
                          dataTestIdlabel="energyUnitsLabel"
                          dataTestIdvalue="energyUnitsValue"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DisplayItem
                          name="Annual Energy Reduction"
                          isHelperTextRequired={false}
                          value={Carrier.annualReduction}
                          dataTestIdlabel="AnnualEnergyReductionLabel"
                          dataTestIdvalue="AnnualEnergyReductionValue"
                          formatLocale
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={4}>
                      <DisplayItem
                        name="Annual GHG Reduction (tCO₂e)"
                        isHelperTextRequired={false}
                        value={Carrier.annualReduction}
                        dataTestIdlabel="AnnualGHGReductionLabel"
                        dataTestIdvalue="AnnualGHGReductionValue"
                      />
                    </Grid>
                  )}

                  <Grid item xs={8}>
                    <DisplayItem
                      name="Notes"
                      isHelperTextRequired={false}
                      value={Carrier.notes}
                      dataTestIdlabel="NotesLabel"
                      dataTestIdvalue="NotesValue"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item xs={12}>
              <Divider data-testid="MeasureDetailsDivider" />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 20,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                }}
                data-testid="MeasureTiming"
              >
                Measure Timing
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Start Year"
                isHelperTextRequired={false}
                value={props.reductionMeasure.startYear}
                dataTestIdlabel="StartYearLabel"
                dataTestIdvalue="StartYearValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Start Quarter"
                isHelperTextRequired={false}
                value={props.reductionMeasure.startQuarter}
                dataTestIdlabel="startQuarterLabel"
                dataTestIdvalue="startQuarterValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="End Year"
                isHelperTextRequired={false}
                value={props.reductionMeasure.endYear}
                dataTestIdlabel="EndYearLabel"
                dataTestIdvalue="EndYearValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="End Quarter"
                isHelperTextRequired={false}
                value={props.reductionMeasure.endQuarter}
                dataTestIdlabel="endQuarterLabel"
                dataTestIdvalue="endQuarterValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Pace"
                isHelperTextRequired={false}
                value={props.reductionMeasure.pace}
                dataTestIdlabel="PaceLabel"
                dataTestIdvalue="PaceValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Execution"
                isHelperTextRequired={false}
                value={props.reductionMeasure.execution}
                dataTestIdlabel="ExecutionLabel"
                dataTestIdvalue="ExecutionValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Tech Lifetime"
                isHelperTextRequired={false}
                value={props.reductionMeasure.techLife}
                dataTestIdlabel="TechLifetimeLabel"
                dataTestIdvalue="TechLifetimeValue"
              />
            </Grid>
            <Grid item xs={3}>
              <DisplayItem
                name="Financial Lifetime"
                isHelperTextRequired={false}
                value={props.reductionMeasure.financialLifetime}
                dataTestIdlabel="FinancialLifetimeLabel"
                dataTestIdvalue="FinancialLifetimeValue"
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayItem
                name="Notes"
                isHelperTextRequired={false}
                value={props.reductionMeasure.measureTimingNotes}
                dataTestIdlabel="NotesLabel"
                dataTestIdvalue="NotesValue"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider data-testid="MeasureTimingDivider" />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 20,
                  fontWeight: "fontWeightBold",
                  color: "#718096",
                }}
                data-testid="MeasureMulti-Criteria"
              >
                Measure Multi-Criteria Assessment (MCA)
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Ease"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_Ease)[
                    props.reductionMeasure.easeRating ?? -1
                  ]
                }
                dataTestIdlabel="EaseLabel"
                dataTestIdvalue="EaseValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Reputation"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_Reputation)[
                    props.reductionMeasure.reputationRating ?? -1
                  ]
                }
                dataTestIdlabel="ReputationLabel"
                dataTestIdvalue="ReputationValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Climate Risk"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_ClimateRisk)[
                    props.reductionMeasure.climateRiskRating ?? -1
                  ]
                }
                dataTestIdlabel="ClimateRiskLabel"
                dataTestIdvalue="ClimateRiskValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Biodiversity"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_Biodiversity)[
                    props.reductionMeasure.biodiversityRating ?? -1
                  ]
                }
                dataTestIdlabel="BiodiversityLabel"
                dataTestIdvalue="BiodiversityValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Community"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_Community)[
                    props.reductionMeasure.communityRating ?? -1
                  ]
                }
                dataTestIdlabel="CommunityLabel"
                dataTestIdvalue="CommunityValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Technology Readiness"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_TechnologyReadiness)[
                    props.reductionMeasure.technologyRedinessRating ?? -1
                  ]
                }
                dataTestIdlabel="TechnologyReadinessLabel"
                dataTestIdvalue="TechnologyReadinessValue"
              />
            </Grid>

            <Grid item xs={4}>
              <DisplayItem
                name="Operational Disruption"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_OperationalDisruption)[
                    props.reductionMeasure.operationalDisruptionRating ?? -1
                  ]
                }
                dataTestIdlabel="OperationalDisruptionLabel"
                dataTestIdvalue="OperationalDisruptionValue"
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayItem
                name="Health and Safety"
                isHelperTextRequired={false}
                value={
                  Object.values(MCA_HealthAndSafety)[
                    props.reductionMeasure.healthSafetyRating ?? -1
                  ]
                }
                dataTestIdlabel="Health and SafetyLabel"
                dataTestIdvalue="Health and SafetyValue"
              />
            </Grid>
          </Grid>
          <br />
          <Divider></Divider>
          <br />
          <React.Fragment>
            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: 20,
                    fontWeight: "fontWeightBold",
                    color: "#718096",
                  }}
                  data-testid="measurefinacials-container"
                >
                  Measure Financials
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
              <Grid item xs={4}>
                <DisplayItem
                  name="CapEx Investment"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.capExInvestment}
                  dataTestIdlabel="capExInvestmentLabel"
                  dataTestIdvalue="capExInvestmentValue"
                  formatLocale={true}
                />
              </Grid>
              <Grid item xs={4}>
                <DisplayItem
                  name="OpEx (No personnel)"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.opEx}
                  dataTestIdlabel="opExLabel"
                  dataTestIdvalue="opExValue"
                  formatLocale={true}
                />
              </Grid>
              <Grid item xs={4}>
                <DisplayItem
                  name="Annual OpEx"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.revenue}
                  dataTestIdlabel="revenueLabel"
                  dataTestIdvalue="revenueValue"
                  formatLocale={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
              <Grid item xs={4}>
                <DisplayItem
                  name="Personnel Time"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.personnelTime}
                  dataTestIdlabel="personnelTimeLabel"
                  dataTestIdvalue="personnelTimeValue"
                />
              </Grid>
              <Grid item xs={4}>
                <DisplayItem
                  name="Personnel Rate"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.personnelRate}
                  dataTestIdlabel="personnelRateLabel"
                  dataTestIdvalue="personnelRateValue"
                  formatLocale={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
              <Grid item xs={8}>
                <DisplayItem
                  name="Financial Notes"
                  isHelperTextRequired={false}
                  value={props.reductionMeasure.financialNotes}
                  dataTestIdlabel="financialNotesLabel"
                  dataTestIdvalue="financialNotesValue"
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </CardContent>
      </Card>
    </Grid>
  );
}
