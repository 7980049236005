import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";

import { CapexPlanResult, CapexQueryModel } from "../Data/CapexPlan";
import { _getOrganizations } from "middlewares/OrganizationApi/organization";

import { _getCapexPlanData } from "middlewares/CapexPlanApi/CapexPlan";
import Spinner from "components/Spinner";

import { useAppState } from "context/appState.context";

import CapexValueRow from "./CapexValueRow";
import CapexOrgNameRow from "./CapexOrgNameRow";
import CapexSelectScenarioRow from "./CapexSelectScenarioRow";
import FilterPanel from "../FilterPanel";

const StyledTableContainer = styled(TableContainer)({
  maxWidth: "100%",
  overflowX: "auto",
});

const CapexPlan: React.FC = () => {
  const { userRole } = useAppState();
  const [loading, setLoading] = useState(true);

  const [organizationOptions, setOrganizationOptions] = useState<
    { id: number; label: string; value: string }[]
  >([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([
    -1,
  ]);
  const [queryModel, setQueryModel] = useState<CapexQueryModel[]>([]);
  const [capexPlanResult, setCapexPlanResult] = useState<CapexPlanResult[]>([]);
  const years = useMemo(() => {
    const minYear = 2020;
    const maxYear = 2050;
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);
  }, [capexPlanResult]);

  useEffect(() => {
    if (userRole !== null && userRole !== "") {
      getEssentialOrganizationDetails();
    }
  }, []);

  /**
   * Fetches organization names from the API (using user role) and updates the state.
   * Sets loading state to true while fetching data and false after completion.
   */
  const getEssentialOrganizationDetails = async () => {
    try {
      const res = await _getOrganizations(userRole);
      if (res) {
        let tempObject: Array<CapexQueryModel> = [];
        const formattedOptions = res.map((org) => ({
          id: org.orgId,
          label: org.orgName,
          value: org.orgId.toString(),
        }));
        const selectAllOption = {
          id: 0,
          label: "Select All",
          value: "0",
        };
        res.forEach((element) => {
          let obj = { OrgId: element.orgId, Scenario: "", ToCompute: true };
          tempObject.push(obj);
        });
        setQueryModel(tempObject);
        setOrganizationOptions([selectAllOption, ...formattedOptions]);
      }
    } catch (err) {
      console.error("CapexPlan getOrganizationNames Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetches the Capex Plan List from the backend and updates the state with the retrieved data.
   *
   * @async
   * @function getCapexPlanList
   * @returns {Promise<void>}
   */
  const getCapexPlanList = async () => {
    try {
      setLoading(true);
      const res = await _getCapexPlanData(queryModel);
      if (res) {
        setCapexPlanResult(res);
      }
    } catch (err) {
      console.error("CapexPlan getCapexPlanList Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      queryModel !== null &&
      queryModel !== undefined &&
      queryModel.length > 0
    ) {
      getCapexPlanList();
    }
  }, [queryModel]);

  const handleScenarioChange = (
    value: string | null | number,
    orgId: number | null
  ) => {
    const updatedScenarioName = value;
    let tempQueryModel = queryModel;
    const itemIndex = tempQueryModel.findIndex((i) => i.OrgId == orgId);
    if (itemIndex > -1) {
      tempQueryModel[itemIndex].Scenario =
        updatedScenarioName != null ? updatedScenarioName.toString() : "";
    }
    setQueryModel(tempQueryModel);
    if (
      queryModel !== null &&
      queryModel !== undefined &&
      queryModel.length > 0
    ) {
      getCapexPlanList();
    }
  };

  const handleOrganizationChange = (selectedOptions: number[]) => {
    setSelectedOrganizations(selectedOptions);
  };

  useEffect(() => {
    let tempQueryModel = queryModel;
    tempQueryModel.forEach((element) => {
      if (selectedOrganizations.indexOf(element.OrgId) > -1) {
        element.ToCompute = true;
      } else if (selectedOrganizations.indexOf(element.OrgId) == -1) {
        element.ToCompute = false;
      }
    });
    setQueryModel(tempQueryModel);
  }, [selectedOrganizations]);

  const handleSearch = async () => {
    if (
      queryModel !== null &&
      queryModel !== undefined &&
      queryModel.length > 0
    ) {
      getCapexPlanList();
    }
  };

  return (
    <Grid>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Spinner size={80} data-testid="spinner" />
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mr: 5 }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold" }}
                data-testid="portfolioView"
              >
                Portfolio View
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold" }}
                data-testid="capexLabel"
              >
                CapEx Plan
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />

          {/* Filter Starts */}

          <FilterPanel
            organizationOptions={organizationOptions}
            selectedOrganizations={selectedOrganizations}
            onOrganizationChange={handleOrganizationChange}
            onSearch={handleSearch}
          />

          {/* Filter Ends */}

          {queryModel.length === 0 ? (
            <Grid item xs={11} style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                color="gray"
                style={{ fontWeight: "bold" }}
                sx={{ mt: 15, mb: 20, ml: 20 }}
              >
                No Capex Data
              </Typography>
            </Grid>
          ) : (
            <StyledTableContainer>
              <Table>
                <TableBody>
                  {/* Org Name Table Row */}

                  <CapexOrgNameRow capexPlanResult={capexPlanResult} />

                  {/* Scenario Table Row */}

                  <CapexSelectScenarioRow
                    capexPlanResult={capexPlanResult}
                    onChange={handleScenarioChange}
                  />

                  {/* Years Table Row */}

                  <CapexValueRow
                    capexPlanResult={capexPlanResult}
                    years={years}
                  />
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      )}
    </Grid>
  );
};

export default CapexPlan;
