import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MultiSelect from "pages/TargetForm/MultiSelect";
import MultSelectNew from "./BaselineEmission/MultSelectNew";


interface FilterPanelProps {
  organizationOptions: { id: number; label: string; value: string }[];
  selectedOrganizations: number[];
  onOrganizationChange: (selectedOptions: number[]) => void;
  showBaseYear?: boolean;
  baseYear?: number;
  baseYearOptions?: number[];
  onBaseYearChange?: (value: number) => void;
  showScope?: boolean;
  scopeOptions?: { id: number; label: string }[];
  selectedScope?: number[];
  onScopeChange?: (selectedOptions: number[]) => void;
  onSearch: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  organizationOptions,
  selectedOrganizations,
  onOrganizationChange,
  showBaseYear = false,
  baseYear,
  baseYearOptions = [],
  onBaseYearChange,
  showScope = false,
  scopeOptions = [],
  selectedScope = [],
  onScopeChange,
  onSearch,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <Grid item xs={12}>
      <Paper
        variant="outlined"
        style={{
          padding: "16px",
          marginLeft: "5px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {/* Toggle Button */}
        <button
          type="button"
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
            width: "100%",
          }}
          data-testid="toggleFiltersButon"
          onClick={toggleFilters}
          aria-pressed={showFilters}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              fontSize: "20px",
              color: "#27272E",
              marginLeft: "4px",
            }}
            data-testid="filtersLabel"
          >
            Filters
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <IconButton>{showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          </div>
        </button>

        {/* Filters Section */}
        {showFilters && (
          <>
            {/* Organizations Filter */}
            <Grid container alignItems="center">
              <Grid item xs={6} sx={{ mr: 5 }}>
                <Typography variant="body1" sx={{ mr: 5 }}>
                  Organizations
                </Typography>
                <MultSelectNew
                  dataTestId="organizationSelect"
                  options={organizationOptions}
                  onSelectionChange={onOrganizationChange}
                  selectedOptions={selectedOrganizations}
                />
              </Grid>

              {/* Base Year Filter (Optional) */}
              {showBaseYear && onBaseYearChange && (
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Base Year</FormLabel>
                    <Select
                      value={baseYear}
                      onChange={(e) => onBaseYearChange?.(Number(e.target.value))}
                      data-testid="baseYearDrodpdown"
                      sx={{ width: "310px" }}
                    >
                      <MenuItem value={0}>Select Start Year</MenuItem>
                      {baseYearOptions.map((option, index) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {/* Scope Filter (Optional) */}
            {showScope && onScopeChange && (
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ m: 1 }}>
                    Scope
                  </Typography>
                  <MultiSelect
                    dataTestId={"scopeSelect"}
                    options={scopeOptions || []}
                    onSelectionChange={onScopeChange}
                    selectedOptions={selectedScope}
                  />
                </Grid>
              </Grid>
            )}

            {/* Search Button */}
            <Grid container justifyContent="flex-end">
              <Grid item sx={{ ml: 2 }}>
                <Button type="submit" variant="contained" onClick={onSearch} data-testid="btnSearch">
                  Search
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default FilterPanel;
