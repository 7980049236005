import { Box, Button, Grid } from "@mui/material";
import Spinner from "components/Spinner/Spinner";
import SnackBar from "components/SnackBar";
import { AlertsProps } from "components/Alerts/data/Alerts.type";

interface LoadingAndSnackbarProps {
  loading: boolean;
  success: AlertsProps;
  handleCloseSnackBar: (state: AlertsProps) => void;
  handleSave: () => void;
}

const LoadingAndSnackbar = ({ loading, success, handleCloseSnackBar,handleSave }: LoadingAndSnackbarProps) => {
  return (
    <>
      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" data-testid="btnSave" onClick={handleSave}>
          Save
        </Button>
      </Box>
      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Spinner size={80} data-testid="spinner" />
        </Grid>
      )}
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
    </>
  );
};

export default LoadingAndSnackbar;
