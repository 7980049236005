import { Divider, Grid, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import { baseYears } from "pages/Dashboards/ProjectRegister/data/ProjectRegisterData";
import { OrganizationModel } from "object-models/organization-model";
import { PortfolioFilter } from "../Data/PortfolioFilter";
import { _getTotalEmissionByScopes } from "middlewares/DashboardApi/portfolioView";
import { useAppState } from "context/appState.context";
import { _getOrganizations } from "middlewares/OrganizationApi/organization";
import EmissionByScopePieChat from "./EmissionByScopePieChat";
import EmissionByScopeCategoryBar from "./EmissionByScopeCategoryBar";
import BaselineYearAndTotal from "./BaselineYearAndTotal";
import TotalEmissionsByScope from "./TotalEmissionsByScope";
import { EmissionData, scopesCoveredOptions } from "../Data/PortfolioViewData";
import Spinner from "components/Spinner";
import { Constants } from "constants/constant";
import FilterPanel from "../FilterPanel";

const BaselineEmission = () => {
  const { userRole } = useAppState();

  const [totalemissions, setTotalemissions] = useState<number>(0);
  const [data, setData] = useState([] as unknown as OrganizationModel);
  const [loading, setLoading] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([
    -1,
  ]);
  const [baseYear, setBaseYear] = useState<number>(Constants.BaseYear);
  const [emissionValues, setEmissionValues] = useState<{
    [key: string]: number;
  }>({});
  const [portfolioFilter, setPortfolioFilter] = useState<PortfolioFilter>({
    OrgId: [],
    BaseYear: baseYear,
    userRole: userRole,
  });

  const [organizationOptions, setOrganizationOptions] = useState<
    { id: number; label: string; value: string }[]
  >([]);

  const [selectedScope, setSelectedScope] = useState(
    scopesCoveredOptions.map((a) => a.id)
  );

  const handleScopeChange = (selectedOptions: number[]) => {
    setSelectedScope(selectedOptions);
    updateEmissionValues(selectedOptions);
  };

  const updateEmissionValues = (selectedOptions: number[]) => {
    const newEmissionValues: { [key: string]: number } = {
      "Scope 1": 0,
      "Scope 2": 0,
      "Scope 3": 0,
    };

    selectedOptions.forEach((option) => {
      switch (option) {
        case 0:
          newEmissionValues["Scope 1"] = Number(data.scope1GHGEmission) || 0;
          break;
        case 1:
          newEmissionValues["Scope 2"] = Number(data.scope2GHGEmission) || 0;
          break;
        case 2:
          newEmissionValues["Scope 3"] = Number(data.scope3GHGEmission) || 0;
          break;
      }
    });

    setEmissionValues(newEmissionValues);
    setTotalemissions(
      newEmissionValues["Scope 1"] +
        newEmissionValues["Scope 2"] +
        newEmissionValues["Scope 3"]
    );
  };

  const handleOrganizationChange = (selectedOptions: number[]) => {
    setSelectedOrganizations(selectedOptions);
    setPortfolioFilter((prevFilter) => ({
      ...prevFilter,
      OrgId: selectedOptions,
    }));
  };

  const handleBaseYearChange = (value: number) => {
    const selectedYear = value;
    setBaseYear(selectedYear);
    setPortfolioFilter((prevFilter) => ({
      ...prevFilter,
      BaseYear: selectedYear,
    }));
  };

  function getEssentialOrganizationDetails() {
    _getOrganizations(userRole)
      .then((res) => {
        if (res) {
          const formattedOptions = res.map((org) => ({
            id: org.orgId,
            label: org.orgName,
            value: org.orgId.toString(),
          }));
          const selectAllOption = {
            id: 0,
            label: "Select All",
            value: "0",
          };
          setOrganizationOptions([selectAllOption, ...formattedOptions]);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        setLoading(false);
      });
  }

  const getTotalEmissionByScopes = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await _getTotalEmissionByScopes(portfolioFilter);

      if (!res || !Array.isArray(res)) return;

      const emissionSums: EmissionData = {};
      const totalEmissions: EmissionData = {
        "Scope 1": 0,
        "Scope 2": 0,
        "Scope 3": 0,
      };

      const aggregateEmissions = (
        key: string,
        value: number | null | undefined
      ) => {
        if (typeof value === "number") {
          emissionSums[key] = (emissionSums[key] || 0) + value;
        }
      };

      res.forEach((org: Partial<OrganizationModel>) => {
        // Aggregate scope emissions
        totalEmissions["Scope 1"] += Number(org.scope1GHGEmission) || 0;
        totalEmissions["Scope 2"] += Number(org.scope2GHGEmission) || 0;
        totalEmissions["Scope 3"] += Number(org.scope3GHGEmission) || 0;

        // Aggregate other emissions
        Object.entries(org).forEach(([key, value]) => {
          if (key !== "orgId") {
            aggregateEmissions(key, Number(value));
          }
        });
      });

      setData({
        orgId: 1,
        ...emissionSums,
      } as OrganizationModel);

      setEmissionValues(totalEmissions);
      setTotalemissions(
        totalEmissions["Scope 1"] +
          totalEmissions["Scope 2"] +
          totalEmissions["Scope 3"]
      );
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEssentialOrganizationDetails();
    getTotalEmissionByScopes();
  }, []);

  useEffect(() => {
    getTotalEmissionByScopes();
  }, [portfolioFilter]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mr: 5 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold" }}
            data-testid="portfolioviewId"
          >
            Portfolio view
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold" }}
            data-testid="baselineEmissionsId"
          >
            Baseline Emissions
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      {/* Filter Starts */}
      <FilterPanel
        organizationOptions={organizationOptions}
        selectedOrganizations={selectedOrganizations}
        onOrganizationChange={handleOrganizationChange}
        showBaseYear={true}
        baseYear={baseYear}
        baseYearOptions={baseYears}
        onBaseYearChange={handleBaseYearChange}
        showScope={true}
        scopeOptions={scopesCoveredOptions}
        selectedScope={selectedScope}
        onScopeChange={handleScopeChange}
        onSearch={getTotalEmissionByScopes}
      />

      {/* Filter Ends */}

      <br />
      <Divider />
      <br />
      {loading ? (
        <Spinner size={80} data-testid="spinner" />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4.8}>
              <BaselineYearAndTotal
                totalEmissions={totalemissions}
                baseYear={baseYear}
              />
            </Grid>
            <Grid item xs={7.2}>
              <TotalEmissionsByScope
                emissionValues={emissionValues}
                selectedScope={selectedScope}
              />
            </Grid>
          </Grid>
          <br />
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 4, mb: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Baseline Assessment
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <EmissionByScopePieChat data={data} selectedScope={selectedScope} />
            <EmissionByScopeCategoryBar data={data} />
          </Grid>
        </>
      )}
    </>
  );
};
export default BaselineEmission;
